import request from '@/utils/request'
const controller = '/ResumeDeliveryArchive'


const getArchive = id =>
  request({
    url: `${controller}/GetInfo`,
    method: 'get',
    params: { id }
  });

export {
  getArchive
}