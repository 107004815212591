<template>
  <div>
    <div class="talent-sub" v-for="item in list" :key="item.Id">
      <div class="avatar">
        <el-avatar :src="item.Avatar" :size="72" />
        <i :class="['avatar-gender',genderIcon(item.Gender)]" />
      </div>
      <div class="info">
        <div>
          <span class="color-base fs-20">{{ item.DisplayName }}</span>
          <span class="fs-14 ml-16">{{item.EducationDescription}}</span>
        </div>
        <div class="flex u-row-between mt-10">
          <div class="flex-column u-row-between">
            <div class="color-gray">
              <span>{{ item.GraduateSchool }}</span>
            </div>
            <div class="mt-8">
              <span>求职意向：</span>
              <span>{{ item.ExpectWorkPlace }} · {{ item.ExpectJobName }} · {{ item.SalaryMin }}K-{{ item.SalaryMax }}K</span>
            </div>
          </div>
          <!-- <div class="flex-column u-row-between">
                  <div>
                    <span>1995-01-11</span>
                    <span>深圳市智邻科技有限公司 · 设计助理</span>
                  </div>
                  <div class="mt-8">
                    <span>1995-01-11</span>
                    <span>深圳市智邻科技有限公司 · 设计助理</span>
                  </div>
                </div> -->
        </div>
      </div>
      <!-- <div v-if="item.IsArchived" class="btns" v-loading="buttonSubmitingLoding(item)">
        <el-button v-if="showArchive(item.IsArchived)" type="primary" plain size="small" @click="viewArchive(item)" icon="el-icon-view">查看归档</el-button>
      </div> -->
      <div class="btns">
        <div class="delivery-job" v-if="item.RecruitJobName">
          <span>投递岗位：</span>
          <span>{{item.RecruitJobName}}</span>
        </div>
        <div class1="btns" v-loading="buttonSubmitingLoding(item)">
          <el-button type="primary" plain size="small" @click="view(item)" icon="el-icon-view">查看</el-button>
          <el-button v-if="showAddCandidate(item.DeliveryStatus)" type="primary" plain size="small" @click="addCandidate(item)" icon="el-icon-circle-plus-outline">加候选</el-button>
          <!-- <el-button v-if="showAddInterview(item.DeliveryStatus)" type="primary" plain size="small" @click="showInterviewDialog(item)" icon="el-icon-date">约面试</el-button> -->
          <el-button v-if="showInterview(item.DeliveryStatus)" type="primary" plain size="small" @click="showInterviewDialog(item,true)" icon="el-icon-date">查看面试</el-button>
          <el-button v-if="showAddAdmit(item.DeliveryStatus)" type="primary" plain size="small" @click="showAdmitDialog(item)" icon="el-icon-date">录取</el-button>
          <el-button v-if="showAddNotSuitable(item.DeliveryStatus)" type="primary" plain size="small" @click="addNotSuitable(item)" icon="el-icon-circle-close">不合适</el-button>
          <el-button v-if="showAddArchive(item.IsArchived,item.DeliveryStatus)" type="primary" plain size="small" @click="addArchive(item)" icon="el-icon-circle-close">归档</el-button>
          <el-button v-if="showArchive(item.IsArchived,item.DeliveryStatus)" type="primary" plain size="small" @click="viewArchive(item)" icon="el-icon-view">已归档</el-button>
          <!-- <el-button type="primary" plain size="small" @click="goChat(item)" icon="el-icon-chat-dot-round">沟通</el-button> -->
        </div>
        <div class="delivery-enterprise" v-if="item.RecruitEmployerName">
          <span>雇主单位：</span>
          <span>{{item.RecruitEmployerName}}</span>
        </div>
      </div>

    </div>
    <el-dialog class="resume" :visible.sync="dialogVisible" width="800px">
      <template slot="title">
        <div class="flex p-28">
          <el-avatar :src="Resume.Avatar" :size="72"> </el-avatar>
          <div class="ml-24 u-flex-1 flex-column mr-24">
            <div>
              <span class="fs-16">{{ Resume.DisplayName }}</span>
              <span :class="[
                  'resume-avatar-gender',
                  Resume.Gender == 2
                    ? 'el-icon-female resume-avatar-gender-female'
                    : 'el-icon-male resume-avatar-gender-male'
                ]"></span>
            </div>
            <!-- <div class="mt-12 fs-12">
              <span>{{ formartDate(Resume.BirthDay, "yyyy-MM") }}</span>
            </div> -->
            <el-row class="mt-8 fs-12">
              <el-col :span="8">
                <span>期望职位：{{ Resume.ExpectJobName }}</span>
              </el-col>
              <el-col :span="8">
                <span>期望薪资：{{ Resume.SalaryMin }}-{{Resume.SalaryMax}}K</span>
              </el-col>
              <el-col :span="8">
                <span>期望地点：{{ Resume.ExpectWorkPlace }}</span>
              </el-col>
            </el-row>
            <el-row class="mt-8 fs-12">
              <el-col :span="8">
                <span>联系电话：{{ Resume.ContactNumber }}</span>
              </el-col>
              <el-col :span="10">
                <span>联系邮箱：{{ Resume.Email }}</span>
              </el-col>
            </el-row>
          </div>
        </div>
      </template>
      <div class="dialog-content">
        <span class="content-title">基本信息</span>
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="info-item text-over">
              <span class="item-type">性别：</span><span class="item-txt">{{ Resume.GenderDescription }}</span>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="info-item text-over">
              <span class="item-type">出生年月：</span><span class="item-txt">{{
                formartDate(Resume.BirthDay, "yyyy-MM")
              }}</span>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="info-item text-over">
              <span class="item-type">籍贯：</span><span class="item-txt">{{ Resume.NativePlace }}</span>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="info-item text-over">
              <span class="item-type">婚姻状况：</span><span class="item-txt">{{ Resume.MaritalStatus }}</span>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="info-item text-over">
              <span class="item-type">政治面貌：</span><span class="item-txt">{{ Resume.PoliticalStatus }}</span>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="info-item text-over">
              <span class="item-type">最高学历：</span><span class="item-txt">{{ Resume.EducationDescription }}</span>
            </div>
          </el-col>
          <!-- <el-col :span="12">
            <div class="info-item text-over">
              <span class="item-type">联系电话：</span><span class="item-txt">{{ Resume.ContactNumber }}</span>
            </div>
          </el-col> -->
          <el-col :span="12">
            <div class="info-item">
              <span class="item-type">现住地址：</span><span class=" present-address">{{ Resume.PresentAddress }}</span>
            </div>
          </el-col>
        </el-row>
        <!-- <span class="content-title">求职意向</span>
        <el-row :gutter="20" v-for="item in Resume.ResumeExpectDtoList" :key="item.Id">
          <el-col :span="12">
            <div class="info-item text-over">
              <span class="item-type">岗位：</span><span class="item-txt">{{ item.ExpectJobName }}</span>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="info-item text-over">
              <span class="item-type">城市：</span><span class="item-txt">{{ item.ExpectWorkPlace }}</span>
            </div>
          </el-col>
          <el-col :span="24">
            <div class="info-item text-over">
              <span class="item-type">薪资：</span><span class="item-txt">{{ item.SalaryMin }}k-{{ item.SalaryMax }}k</span>
            </div>
          </el-col>
        </el-row> -->
        <span class="content-title">工作经历</span>
        <el-row :gutter="20" v-for="item in Resume.ResumeCareerDtoList" :key="item.Id">
          <el-col :span="24">
            <div class="info-item text-over flex u-row-between">
              <span class="item-txt color-base fs-16">{{ item.JobName }}</span>
              <span class="item-txt color-light fs-12">
                {{ formartDate(item.StartTime, "yyyy-MM-dd") }} 至
                {{ formartDate(item.EndTime, "yyyy-MM-dd") }}
              </span>
            </div>
          </el-col>
          <el-col :span="24">
            <div class="info-item text-over">
              <span class="item-type">描述：</span><span class="item-txt">{{ item.Description }}</span>
            </div>
          </el-col>
        </el-row>
        <span class="content-title">自我评价：</span>
        <el-row :gutter="20">
          <el-col :span="24">
            <div class="info-item text-over">
              <span class="item-txt">{{ Resume.Introduction }}</span>
            </div>
          </el-col>
        </el-row>
      </div>
      <!-- <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span> -->
    </el-dialog>

    <el-dialog title="面试邀请" :visible.sync="interviewVisible" :close-on-press-escape="false" :close-on-click-modal="false" width="600px">
      <el-form ref="interviewForm" :model="interviewForm" :rules="interviewRules" label-width="80px" :disabled="interviewFormDisabled">
        <!-- <el-checkbox v-model="interviewForm" style="margin-left:12px;">同时向
          <span style="padding-left:8px;">{{interviewForm.UserName}}</span>
          <span style="padding-left:8px;">({{ interviewForm.Email }})</span>发送通知
        </el-checkbox> -->
        <el-form-item label="面试方式" prop="Type" required>
          <el-radio-group v-model="interviewForm.Type">
            <el-radio :label="InterviewType.Online">线上面试</el-radio>
            <el-radio :label="InterviewType.Offline">线下面试</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="面试时间" required>
          <el-col :span="11">
            <el-form-item prop="InterviewDate">
              <el-date-picker v-model="interviewForm.InterviewDate" :editable="false" :picker-options="datePickerOptions" value-format="yyyy-MM-dd" type="date" placeholder="选择日期" />
            </el-form-item>
          </el-col>
          <el-col class="line" :span="2" style="text-align: center;">-</el-col>
          <el-col :span="11">
            <el-form-item prop="InterviewTime">
              <el-time-select v-model="interviewForm.InterviewTime" :editable="false" :picker-options="timePickerOptions" value-format="HH:mm:ss" placeholder="选择时间" />
            </el-form-item>
          </el-col>
        </el-form-item>
        <el-form-item v-if="interviewForm.Type" label="面试地点" prop="Address">
          <el-input v-model="interviewForm.Address" placeholder="请输入" />
        </el-form-item>
        <el-form-item label="备注信息" prop="Remark">
          <el-input v-model="interviewForm.Remark" type="textarea" :rows="4" placeholder="请输入" />
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button v-if="!interviewFormDisabled" @click="interviewVisible = false">取 消</el-button>
        <el-button type="primary" @click="addInterview">确 定</el-button>
      </span>

    </el-dialog>
  </div>
</template>

<script>
import { getInfo } from "@/api/resume";
import { getInterview, getResume } from '@/api/resumedelivery';
import { getArchive } from '@/api/resumedeliveryarchive';
import { generateChatGroup } from "@/api/chat";
import { dateTimeFilter } from "@/filters";
import { GenderEnum, InterviewTypeEnum, ResumeDeliveryStatusEnum } from '@/utils/constant';
export default {
  props: {
    list: {
      type: Array,
      default: () => []
    },
    isDelivered: {
      type: Boolean,
      default: false
    },
    buttonSubmiting: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialogVisible: false,
      interviewVisible: false,
      currentResumeDelivery: {},
      Resume: {},
      InterviewType: {
        Online: InterviewTypeEnum.Online,
        Offline: InterviewTypeEnum.Offline
      },
      interviewFormDisabled: true,
      interviewForm: {
        Type: undefined,
        Time: undefined,
        InterviewDate: undefined,
        InterviewTime: undefined,

        Address: '',
        Remark: ''
      },
      interviewRules: {
        Type: {
          required: true,
          message: "请选择方式",
          trigger: "blur"
        },
        InterviewDate: {
          required: true,
          message: "请输入日期",
          trigger: "blur"
        },
        InterviewTime: {
          required: true,
          message: "请输入时间",
          trigger: "blur"
        },
        Address: {
          required: true,
          message: "请输入地址",
          trigger: "blur"
        }
      },
      datePickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 24 * 60 * 60 * 1000;
        }
      },
      timePickerOptions: {
        start: '08:00',
        step: '00:15',
        end: '21:00'
      },
      interviewDate: '',
      interviewTime: '',
      componentsButtonSubmitingLoding: false
    };
  },
  computed: {
    genderIcon() {
      return gender => {
        switch (gender) {
          case GenderEnum.Male:
            return 'el-icon-male avatar-gender-male';
          case GenderEnum.Female:
            return 'el-icon-female avatar-gender-female';
          default:
            return '';
        };
      };
    },
    formartDate() {
      return (data, formate) => dateTimeFilter(data, formate);
    },
    showAddCandidate({ isDelivered }) {
      return status => isDelivered && status === ResumeDeliveryStatusEnum.Delivery;
    },
    showAddInterview({ isDelivered }) {
      return status => isDelivered && status === ResumeDeliveryStatusEnum.Candidate;
    },
    showInterview({ isDelivered }) {
      return status => isDelivered && status === ResumeDeliveryStatusEnum.Interview;
    },
    showAddAdmit({ isDelivered }) {
      return status => isDelivered && status === ResumeDeliveryStatusEnum.Interview;
    },
    showAddNotSuitable({ isDelivered }) {
      return status => isDelivered && (status === ResumeDeliveryStatusEnum.Delivery || status === ResumeDeliveryStatusEnum.Candidate || status === ResumeDeliveryStatusEnum.Interview);
    },
    showAddArchive({ isDelivered }) {
      return (isArchived, status) => isDelivered && !isArchived && status === ResumeDeliveryStatusEnum.Admit;
    },
    showArchive({ isDelivered }) {
      return (isArchived, status) => isDelivered && isArchived && status === ResumeDeliveryStatusEnum.Admit;
    },
    buttonSubmitingLoding({ buttonSubmiting, isDelivered, componentsButtonSubmitingLoding, currentResumeDelivery: { DeliveryId } = {} }) {
      return item => isDelivered && DeliveryId === item.DeliveryId && (buttonSubmiting || componentsButtonSubmitingLoding);
    }
  },
  methods: {
    addCandidate(item) {
      this.currentResumeDelivery = item;
      this.$confirm('确认添加该候选人?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => this.$emit('add-candidate', item)).catch(() => { });
    },
    addNotSuitable(item) {
      this.currentResumeDelivery = item;
      this.$confirm('该候选人不合适?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => this.$emit('add-not-suitable', item)).catch(() => { });
    },
    showInterviewDialog(item, disabled) {
      this.currentResumeDelivery = item;
      this.interviewFormDisabled = disabled;
      if (disabled) {
        getInterview(item.DeliveryId).then(({ Result }) => {
          this.interviewForm = Object.assign({}, this.interviewForm, {
            InterviewDate: Result.Time,
            InterviewTime: Result.Time.split(' ')[1]
          }, Result);

          this.interviewVisible = true;
        }).catch(() => this.$message.error('查看失败'));
      } else {
        this.interviewVisible = true;
        this.$nextTick(() => {
          this.$refs['interviewForm'].resetFields();
          this.interviewForm.Type = InterviewTypeEnum.Online;
        });
      }
    },
    addInterview() {
      if (this.interviewFormDisabled) {
        return this.interviewVisible = false;
      }
      this.$refs["interviewForm"].validate(async isValid => {
        if (!isValid) {
          return;
        }
        const { InterviewDate, InterviewTime } = this.interviewForm;
        const param = Object.assign({}, this.interviewForm,
          {
            DeliveryId: this.currentResumeDelivery.DeliveryId,
            UserId: this.currentResumeDelivery.UserId,
            ResumeId: this.currentResumeDelivery.Id,
            Time: `${InterviewDate} ${InterviewTime}:00`
          });
        this.$emit('add-interview', param);
        this.interviewVisible = false;
      });
    },
    showAdmitDialog(item) {
      this.$confirm('确认录取该候选人?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => this.$emit('add-admit', item)).catch(() => { });
    },
    viewArchive({ ArchiveId }) {
      getArchive(ArchiveId).then(({ Result: { Content } }) => {
        this.Resume = JSON.parse(Content);
        this.dialogVisible = true;
      }).catch(_ => this.$message.error('操作失败'));
    },
    addArchive(item) {
      this.currentResumeDelivery = item;
      this.$confirm('确认简历归档?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => this.$emit('add-archive', item)).catch(() => { });
    },
    view(item) {
      const task = this.isDelivered ? getResume(item.DeliveryId) : getInfo(item.Id);
      task.then(({ Result }) => {
        this.dialogVisible = true;
        this.Resume = Result;
      });
    },
    async goChat(item) {
      this.currentResumeDelivery = item;
      const param = {
        UserId: item.UserId,
        JobId: item.RecruitJobId
      };
      try {
        this.componentsButtonSubmitingLoding = true;
        const { Result: { ChatGroupId, ResumeId } = {} } = await generateChatGroup(param);
        if (ChatGroupId) {
          this.$router.push({ name: "EnterpriseChat", params: { ChatGroupId, ResumeId } }); // 只能用 name
        }
      } catch (error) {
        this.$message.error('操作失败');
        this.componentsButtonSubmitingLoding = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.talent-sub {
  display: flex;
  border-radius: 3px;
  align-items: center;
  margin-bottom: 20px;
  padding: 16px;
  box-sizing: border-box;
  border-bottom: 1px solid #eee;
  font-size: 14px;
  color: #999;
  cursor: pointer;
  &:hover {
    box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.05);
  }
  .avatar {
    position: relative;
    align-self: flex-start;
    &-gender {
      position: absolute;
      top: 0;
      right: 0;
      color: #fff;
      font-size: 16px;
      padding: 2px;
      border-radius: 50%;
      &-male {
        background: #557df7;
      }
      &-female {
        background: #ff6677;
      }
    }
  }
  .info {
    flex: 1;
    margin-left: 19px;
  }
  .btns {
    margin-left: 120px;
  }
}
.dialog-content {
  min-height: 400px;
  display: flex;
  padding: 10px 28px;
  flex-direction: column;
  // overflow-y: auto;
  .content-title {
    margin-bottom: 16px;
    &::before {
      content: '';
      vertical-align: -3px;
      border-radius: 2px;
      display: inline-block;
      width: 3px;
      height: 16px;
      background-color: #598DF3;
      margin-right: 5px;
    }
  }
}
.info-item {
  display: flex;
  // justify-content: space-between;
  align-items: baseline;
  padding: 10px 5px;
  .item-type {
    color: #999;
  }
}
</style>
<style lang="scss">
.resume {
  .el-dialog__header {
    background: linear-gradient(360deg, #14a495 0%, #598DF3 100%);
    color: #fff;
    .resume-avatar-gender {
      color: #fff;
      font-size: 16px;
      padding: 2px;
      margin-left: 8px;
      border-radius: 50%;
      &-male {
        background: #557df7;
      }
      &-female {
        background: #ff6677;
      }
    }
  }
}
.delivery-job {
  text-align: right;
  margin-bottom: 10px;
  font-size: 16px;
}
.delivery-enterprise {
  text-align: right;
  margin-top: 10px;
  font-size: 16px;
}
</style>
