import request from '@/utils/request'
const controller = '/ResumeDelivery'

const getPageList = params =>
  request({
    url: `${controller}/GetPageList`,
    method: 'get',
    params
  });

const getStatistic = id =>
  request({
    url: `${controller}/GetStatistic`,
    method: 'get',
    params: { id }
  });


const addCandidate = id =>
  request({
    url: `${controller}/AddCandidate`,
    method: 'post',
    params: { id }
  });
const addInterview = data =>
  request({
    url: `${controller}/Interview`,
    method: 'post',
    data
  });
const addNotSuitable = id =>
  request({
    url: `${controller}/AddNotSuitable`,
    method: 'post',
    params: { id }
  });
const addAdmit = id =>
  request({
    url: `${controller}/Admit`,
    method: 'post',
    params: { id }
  });
const addArchive = id =>
  request({
    url: `${controller}/Archive`,
    method: 'post',
    params: { id }
  });
const changeStatus = data =>
  request({
    url: `${controller}/ChangeStatus`,
    method: 'post',
    data
  });
const getResume = deliveryId =>
  request({
    url: `${controller}/GetResume?id=${deliveryId}`,
    method: 'get'
  });

const getInterview = deliveryId =>
  request({
    url: `${controller}/GetInterview?id=${deliveryId}`,
    method: 'get'
  });
export {
  getStatistic,
  getPageList,
  addCandidate,
  addInterview,
  addNotSuitable,
  addAdmit,
  addArchive,
  changeStatus,
  getResume,
  getInterview
}